export default {
  classes: {
    hiddeParrent: 'batcom-hide-parent',
    blurry: 'batcom-container--blurry',
  },
  visibilityRestrictionOptions: {
    loggedIn: 'LOGGED_IN',
    loggedOut: 'LOGGED_OUT',
  },
  visibilityNewsletterRestrictionOptions: {
    fullyVisible: 'SIGNED_UP_TO_NEWSLETTER',
    onlyVisible: 'NOT_SIGNED_UP_TO_NEWSLETTER',
  },
};
