import { register } from '@netcentric/component-loader';
import { getCookie } from 'commons/utils';
import config from './batcom-container.config';

class Container {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.init();
  }

  init() {
    this.setVisibilityByAuthentitication();
    this.setVisibilityByNewsletter();
  }

  setVisibilityByAuthentitication() {
    const isUserAuthenticated = !!getCookie(this.config.authenticationCookie);
    const isVisible =
      (this.config.visibilityRestriction === this.config.visibilityRestrictionOptions.loggedIn && isUserAuthenticated) ||
      (this.config.visibilityRestriction === this.config.visibilityRestrictionOptions.loggedOut && !isUserAuthenticated);
    if (isVisible) {
      this.el.classList.remove(this.config.classes.hiddeParrent);
    }
  }

  setVisibilityByNewsletter() {
    const isUserSignedUp = !!getCookie(this.config.newsletterSignedUpCookie);
    if (this.config.visibilityRestriction === this.config.visibilityNewsletterRestrictionOptions.fullyVisible && isUserSignedUp) {
      // Fully visible content 
      this.el.classList.remove(this.config.classes.hiddeParrent);
    } else if (this.config.visibilityRestriction === this.config.visibilityNewsletterRestrictionOptions.fullyVisible && !isUserSignedUp) {
      // Fully visible content which is blurry without cookie
      this.el.classList.add(this.config.classes.blurry);
      this.el.classList.remove(this.config.classes.hiddeParrent);
    }
    
    if (this.config.visibilityRestriction === this.config.visibilityNewsletterRestrictionOptions.onlyVisible && !isUserSignedUp) {
      // Only visible content is shown without cookie
      this.el.classList.remove(this.config.classes.hiddeParrent);
    }
  }
}
register({ Container });
